import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import instance from '@/hook/axiosUtils'

export const useConversationStore = defineStore('conversation', () => {
  // const count = ref(0)
  // const doubleCount = computed(() => count.value * 2)
  // function increment() {
  //   count.value++
  // }
  const conversation = ref({
    chatsId: '',
    title: 'How can I help you?'
  })
  const conversationList = ref([])
  const getConversationById = async (chatsId) => {
    await instance.get(`/chats`).then((res) => {
      // const test = conversationList.value
      conversationList.value.splice(0, conversationList.value.length, ...(res.data||[]))
      const foundConversation = conversationList.value.find((item) => String(item.id) === String(chatsId))
      if (chatsId && foundConversation) {
        conversation.value.chatsId = foundConversation.id
        conversation.value.title = foundConversation.title
      }
  })

}
  function $reset() {
    // debugger
    Object.assign(conversation.value, {
        chatsId: '',
        title: 'How can I help you?'
      })
  }

  return { conversation, conversationList, getConversationById, $reset }
})

export const useChatStore = defineStore('chat', () => {
  const list = reactive([])
  const imageUrl = ref('')
  // 目前1 是请求中 2是流式绘制中
  const status = ref(0)

  function sendMessage(params) {
    if(params.imageUrl) {
      imageUrl.value = params.imageUrl
    }
    list.push({
      imageUrl: params.imageUrl,
      userInput: params.userInput
    })
    status.value = 1
    instance.post('/chatmessage/chat', params).then((res) => {
      // const item = list.pop()
      const itemList = splitWorkflow(res.data)
      if(itemList.length > 1) {
        list.push(...itemList)
      } else {
        list.push({...itemList})
      }
    }).finally(() => {
      status.value = 0
    })
  }
  function getAllMessage(chatId, callback) {
    list.splice(0, list.length)
    instance.get(`/chat/${chatId}/messages`).then((res) => {
      // list.splice(0, list.length, ...res.data.reverse())
      let protocolUrl = ''
      if(res.data) {
        res.data.reverse().map((item) => {
          const itemList = splitWorkflow(item)
          if(itemList.length > 1) {
            list.push(...itemList)
          } else {
            list.push({...itemList})
          }
          protocolUrl = item.imageProto || protocolUrl
        })
        imageUrl.value = list[0].imageUrl
      }
      if(protocolUrl && callback) {
        callback(protocolUrl)
      }
    })
  }
  function $reset() {
    list.splice(0, list.length)
    status.value = 0
    imageUrl.value = ''
  }
  return { list, status, imageUrl, sendMessage, getAllMessage, $reset}
})

function splitWorkflow(aioutputData) {
  const output = aioutputData.aiOutput
  let res = []
  if(output && output.includes('<workflow>') && output.includes('</workflow>')) {
    const pre = output.split('<workflow>')[0]
    const content = output.split('<workflow>')[1].split('</workflow>')[0]
    const post = output.split('</workflow>')[1];
    pre && res.push({...aioutputData, aiOutput: pre});
    content && res.push({...aioutputData, aiOutput: content, workflow: true});
    post && res.push({...aioutputData, aiOutput: post});
    return res
  }
  return aioutputData
}